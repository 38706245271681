import request from '../request.js'

export const list = (data)=>{
	return request({
		url:"/customer/list/",
		method:"post",
		data
	})
}

export const distributionList = data=>{
	return request({
		url:"/customer/list/distribution/",
		method:"get",
		params:data
	})
}


export const add = data=>{
	return request({
		url:"/customer/add/",
		method:"post",
		data
	})
}

export const del = customer_id=>{
	return request({
		url:"/customer/delete/",
		method:"post",
		data:{
			customer_id
		}
	})
}

export const edit = data=>{
	return request({
		url:"/customer/edit/",
		method:"post",
		data
	})
}

export const checkExcel = data=>{
	return request({
		url:"/customer/export/",
		method:"get",
		params:data,
	})
}

export const excel = data=>{
	return request({
		url:"/customer/export/",
		method:"get",
		params:data,
		responseType:"blob"
	})
}