import request from '../request.js'

export const userList = (data)=>{
	return request({
		url:"account/list/",
		method:"get",
		params:data
	})
}

// 更改授信额度
export const editSx = data=>{
	return request({
		url:"account/edit/credit_line/",
		method:"post",
		data
	})
}

//更改信用状态
export const editStatus = data=>{
	return request({
		url:"account/edit/credit_status/",
		method:"post",
		data
	})
}

// 邀请注册
export const inviteRegister = data=>{
	return request({
		url:"account/add/account/",
		method:"post",
		data
	})
}

//修改note
export const edit = data=>{
	return request({
		url:"account/edit/info/",
		method:"post",
		data
	})
}


export const editRecodes = data=>{
	return request({
		url:"/account/list/credit_line/",
		method:"get",
		params:data
	})
}

//BD 列表
export const BDList = ()=>{
	return request({
		url:"/user/list/BD/",
		method:"get",
		params:{}
	})
}

export const customerInviteList = (data)=>{
	return request({
		url:"/customer/list/invite/",
		method:"get",
		params:data,
		unLoading:true
	})
}

export const checkExcel = data=>{
	return request({
		url:"/account/export/",
		method:"get",
		params:data,
	})
}

export const excel = data=>{
	return request({
		url:"/account/export/",
		method:"get",
		params:data,
		responseType:"blob"
	})
}

export const disr = data=>{
	return request({
		url:"/account/edit/",
		method:"post",
		data
	})
}

//调整预警值
export const editAlarm = data=>{
	return request({
		url:"/account/edit/alarm_credit/",
		method:"post",
		data
	})
}