/* 
	数字增加小数点
 */
export const numberFixed = (data, c = 3) => {
	data = data.toFixed(c).split('.')
	let reg = /(?=(\B)(\d{3})+$)/g;
	data[0] = data[0].replace(reg, ',')
	data = data.join('.')
	return data
	// return data
}

export const dataURLtoBlob = (dataurl) => {
	// var arr = dataurl.split(','),
	// 	mime = arr[0].match(/:(.*?);/)[1],
	// 	bstr = atob(arr[1]),
	var bstr = atob(dataurl),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], {
		type: 'file'
	});
}

export const downloadFile = (url, name = '文件默认名') => {
	var a = document.createElement("a")
	a.setAttribute("href", url)
	a.setAttribute("download", name)
	a.setAttribute("target", "_blank")
	let clickEvent = document.createEvent("MouseEvents");
	clickEvent.initEvent("click", true, true);
	a.dispatchEvent(clickEvent);
}

export const downloadFileByBase64 = (base64, name) => {
	var myBlob = dataURLtoBlob(base64)
	var myUrl = URL.createObjectURL(myBlob)
	downloadFile(myUrl, name)
}

// var yourBase64 = '输入文件base64编码'
// var youFileName = '输入你的文件名'
// downloadFileByBase64(yourBase64, youFileName)
